export default {
    theme: "Black",
    cards: [
        {
            useCases: [
                {
                    useCase: "Social media planner"
                },
                {
                    useCase: "Marketing strategy"
                },
                {
                    useCase: "Content calendar"
                },
                {
                    useCase: "Campaign planning"
                }
            ],
            cardAsset: "/Generator_featured images/Homepage - 2024/teams- firstfold/Creative.png",
            tag: {
                tagText: "Creative & Design",
                tagBackgroundColor: "Brand & WM Primary Color",
                overridePageCluster: "marketing"
            },
            productType: "work-management"
        },
        {
            useCases: [
                {
                    useCase: "Project management"
                },
                {
                    useCase: "Task management"
                },
                {
                    useCase: "Resource management"
                },
                {
                    useCase: "Portfolio management"
                }
            ],
            tag: {
                tagText: "Sales & CRM",
                overridePageCluster: "crm",
                tagBackgroundColor: "Marketer Lighter Tint 01"
            },
            cardAsset: "/Generator_featured images/Homepage - 2024/teams- firstfold/CRM.png"
        },
        {
            useCases: [
                {
                    useCase: "Design tasks"
                },
                {
                    useCase: "Digital Asset Management "
                },
                {
                    useCase: "Creative requests"
                },
                {
                    useCase: "Client campaigns "
                }
            ],
            tag: {
                tagText: "HR",
                tagBackgroundColor: "brand-green-dark-tint-01"
            },
            cardAsset: "/Generator_featured images/Homepage - 2024/teams- firstfold/HR.png"
        },
        {
            useCases: [
                {
                    useCase: "Sales pipeline"
                },
                {
                    useCase: "Lead management"
                },
                {
                    useCase: "Mass email & tracking"
                },
                {
                    useCase: "Contact management"
                }
            ],
            tag: {
                tagText: "Projects & Tasks",
                tagBackgroundColor: "CRM Primary color",
                overridePageCluster: "product_management"
            },
            cardAsset: "/Generator_featured images/Homepage - 2024/teams- firstfold/Projects.png"
        },
        {
            useCases: [
                {
                    useCase: "IT service desk"
                },
                {
                    useCase: "Incident management"
                },
                {
                    useCase: "Inventory management "
                },
                {
                    useCase: "SLA tracking"
                }
            ],
            tag: {
                tagText: "Product & Dev",
                tagBackgroundColor: "Projects Primary Color"
            },
            cardAsset: "/Generator_featured images/Homepage - 2024/teams- firstfold/Dev.png"
        },
        {
            useCases: [
                {
                    useCase: "Event management "
                },
                {
                    useCase: "Facilities requests"
                },
                {
                    useCase: "Finance requests "
                },
                {
                    useCase: "Order management "
                }
            ],
            tag: {
                tagText: "Marketing",
                tagBackgroundColor: "Projects Lighter Tint 01",
                overridePageCluster: "marketing"
            },
            cardAsset: "/Generator_featured images/Homepage - 2024/teams- firstfold/Marketing.png"
        },
        {
            useCases: [
                {
                    useCase: "Roadmap planning"
                },
                {
                    useCase: "Sprint management "
                },
                {
                    useCase: "Feature backlog "
                },
                {
                    useCase: "Bug tracking"
                }
            ],
            tag: {
                tagText: "IT & Support",
                tagBackgroundColor: "Projects Darkest Tint 03",
                overridePageCluster: "information_technology"
            },
            cardAsset: "/Generator_featured images/Homepage - 2024/teams- firstfold/IT.png"
        },
        {
            useCases: [
                {
                    useCase: "Recruitment pipeline"
                },
                {
                    useCase: "Engagement survey"
                },
                {
                    useCase: "HR requests"
                },
                {
                    useCase: "Onboarding & offboarding"
                }
            ],
            tag: {
                tagText: "Operations",
                tagBackgroundColor: "Brand Yellow (darker) Tint 02",
                overridePageCluster: "generic"
            }
        }
    ]
};
