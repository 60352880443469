export const CAROUSEL_PROPS = {
    dots: false,
    centerMode: false,
    infinite: true,
    slidesToScroll: 2,
    draggable: false,
    easing: "ease-in-out",
    arrows: true,
    focusOnSelect: false,
    showFocusEffect: false
};
import { CRM_PRODUCT_ID, DEV_PRODUCT_ID, SERVICE_PRODUCT_ID, WORK_MANAGEMENT_PRODUCT_ID } from "constants/products";
import { CRM_DARK_TINT_01_COLOR_NAME, DARK_DEV_GREEN_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME, BRAND_SERVICE_PRIMARY_COLOR_NAME } from "styles/color-consts";
export const PRODUCT_ID_TO_LOGO = {
    [WORK_MANAGEMENT_PRODUCT_ID]: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/teams-%20firstfold/3-6-2024/wm.png",
    [CRM_PRODUCT_ID]: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/teams-%20firstfold/3-6-2024/crm.png",
    [DEV_PRODUCT_ID]: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/teams-%20firstfold/3-6-2024/dev.png",
    [SERVICE_PRODUCT_ID]: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/teams-%20firstfold/3-6-2024/service.png"
};
export const PRODUCT_ID_TO_COLOR = {
    [WORK_MANAGEMENT_PRODUCT_ID]: WORK_OS_IRIS_COLOR_NAME,
    [CRM_PRODUCT_ID]: CRM_DARK_TINT_01_COLOR_NAME,
    [DEV_PRODUCT_ID]: DARK_DEV_GREEN_COLOR_NAME,
    [SERVICE_PRODUCT_ID]: BRAND_SERVICE_PRIMARY_COLOR_NAME
};
export const BUTTON_CONFIG_BY_PRODUCT = {
    [WORK_MANAGEMENT_PRODUCT_ID]: {
        signupProductId: WORK_MANAGEMENT_PRODUCT_ID
    },
    [CRM_PRODUCT_ID]: {
        signupProductId: CRM_PRODUCT_ID
    },
    [DEV_PRODUCT_ID]: {
        signupProductId: DEV_PRODUCT_ID
    },
    [SERVICE_PRODUCT_ID]: {
        signupProductId: SERVICE_PRODUCT_ID
    }
};
