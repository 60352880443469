import styled from "@emotion/styled";
export const StyledCarouselTagsComponent = styled.div`
  .carousel {
    .slick-slider .slick-list .slick-track {
      padding-top: 0;
      display: flex;

      .slick-slide {
        width: auto !important;
        height: auto;

        & > div:first-child {
          height: 100%;

          .carousel-item {
            height: 100%;

            .carousel-product-card {
              height: ${({ cardHeight })=>cardHeight || "100%"};
            }
          }
        }
      }
    }

    &.slick-active {
      .carousel-arrow-wrapper {
        border-color: transparent;
      }
    }
  }
`;
