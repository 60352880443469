import { useEffect, useMemo, useState } from "react";
import { CARD_HORIZONTAL_MARGIN, CARD_WIDTH, SMALLER_CARDS_WIDTH } from "segments/desktop/grid/layouts/carousel-selectable-product-cards-grid/desktop-carousel-tags/selectable-product-card/selectable-product-card.scss";
import { MD_DESKTOP } from "constants/vp-sizes";
const SLIDE_WIDTH = CARD_WIDTH + 2 * CARD_HORIZONTAL_MARGIN;
const SMALLER_SLIDE_WIDTH = SMALLER_CARDS_WIDTH + 2 * CARD_HORIZONTAL_MARGIN;
const getSlideWidth = (windowWidth)=>windowWidth >= MD_DESKTOP ? SLIDE_WIDTH : SMALLER_SLIDE_WIDTH;
export const useNumberOfSlides = ()=>{
    const [windowWidth, setWindowWidth] = useState(typeof window !== "undefined" && window.innerWidth);
    const numberOfSlides = useMemo(()=>{
        return windowWidth ? windowWidth / getSlideWidth(windowWidth) : undefined;
    }, [
        windowWidth
    ]);
    useEffect(()=>{
        const handleResize = ()=>setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return ()=>window.removeEventListener("resize", handleResize);
    }, []);
    return numberOfSlides;
};
