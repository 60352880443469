import styled from "@emotion/styled";
import { TOP_LAYER } from "constants/z-index";
import { MID_HEADER_DESKTOP_MENU } from "constants/vp-sizes";
export const StyledDesktopCarouselTagsComponent = styled.div`
	.carousel {
    &.slick-active {
      .carousel-arrow-wrapper {
        border-color: transparent;
      }
    }

    .carousel-arrow-wrapper.slick-arrow {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
      height: fit-content;
      width: fit-content;
      z-index: ${TOP_LAYER};

      &:focus {
        outline: none;
      }

      &.next {
        right: 60px;
      }

      &.prev {
        left: 60px;
      }
    }
  }

  @media (max-width: ${MID_HEADER_DESKTOP_MENU}px) {
    .carousel {
      .carousel-arrow-wrapper.slick-arrow {
        &.next {
          right: 40px;
        }

        &.prev {
          left: 40px;
        }
      }
    }
  }
`;
