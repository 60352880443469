import styled from "@emotion/styled";
import colors from "styles/colors";
import Colors from "styles/colors";
import { UI_BORDER_LIGHT_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
export const StyledMobileCard = styled.div`
  border-radius: 12px;
  background-color: #343751;
  padding: 4px;
  position: relative;
  overflow: hidden;
  margin: 0 4px;

  .picture-wrapper {
    .picture-component {
      display: flex;

      img {
        border-radius: 8px;
        width: 100%;
      }
    }
  }

  .card-content {
    width: 100%;
    padding: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #343751;

    .paragraph-wrapper {
      .paragraph-body {
        font-size: 1.5rem;
        font-weight: 600;
        color: ${Colors[WHITE_COLOR_NAME]};
      }
    }

    .use-cases-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 4px;
      padding-top: 16px;

      .use-case {
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 0.875rem;
        color: ${colors[UI_BORDER_LIGHT_COLOR_NAME]};
      }
    }
  }
`;
