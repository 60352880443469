import styled from "@emotion/styled";
import { WHITE_COLOR_NAME } from "styles/color-consts";
import Colors from "styles/colors";
export const ARROW_TYPES = {
    PREV: "prev",
    NEXT: "next"
};
export const StyledArrow = styled.div`
  height: 100%;
  background: ${({ type })=>type === ARROW_TYPES.PREV ? "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);" : "linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 90.42%);"}
  position: absolute;
  top: 0;
  ${({ type })=>type === ARROW_TYPES.PREV ? "left: 0;" : "right: 0;"};
  width: 200px;
  z-index: 1;

  .carousel-arrow-wrapper {
    &:hover {
      svg circle {
        stroke: white;
      }
    }
  }
`;
